.about-container {
    padding: 1em;

    @media (min-width: 600px) {
        padding: 2em;
    }
}

.about-title {
    margin-bottom: 1.5em;
    color: #212121; // Adjust this to your preferred color
}

.about-text {
    line-height: 1.6;
    color: #666; // Adjust this to your preferred color
}