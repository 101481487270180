.user-msg {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
    color: azure;

    &.success {
        background-color: rgb(15, 122, 15);
    }

    &.danger {
        background-color: rgb(122, 11, 11);
    }
}