/* Reset some basic elements */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif; // Change this to your preferred font
    line-height: 1.6;
    color: #333; // Change this to your preferred color
    background-color: #f9f9f9; // Change this to your preferred color
}

// .app {
//     display: flex;
//     flex-direction: column;
// }

/* Global container */
.container {
    width: 100%;
    max-width: 1200px; // You can adjust this value
    margin: 0 auto;
    position: relative;
    padding-top: 3.5rem;
}

/* Links */
a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

/* If you're using flexbox */
.flex {
    display: flex;
}

/* Helper classes for spacing */
.mt-1 {
    margin-top: 1em;
}

.mr-1 {
    margin-right: 1em;
}

.mb-1 {
    margin-bottom: 1em;
}

.ml-1 {
    margin-left: 1em;
}

.mt-2 {
    margin-top: 2em;
}

.mr-2 {
    margin-right: 2em;
}

.mb-2 {
    margin-bottom: 2em;
}

.ml-2 {
    margin-left: 2em;
}

/* Helper classes for text */
.text-center {
    text-align: center;
}

/* You can continue to add global styles and helper classes as needed */